import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { Picker } from 'emoji-mart';

import { EmojisService } from '../../../services/emojis.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule],
  selector: 'app-emoji-picker',
  templateUrl: './emoji-picker.component.html',
  styleUrls: ['./emoji-picker.component.scss'],
})
export class EmojiPickerComponent implements OnInit {
  callback: (emoji: any) => {};

  constructor(private emojisService: EmojisService) {}

  async ngOnInit() {
    await this.emojisService.loadEmojis();

    const picker: any = new Picker({
      onEmojiSelect: (emoji: any) => {
        this.callback(emoji);
      },
      custom: [
        {
          id: 'custom',
          name: 'Custom',
          emojis: this.emojisService.customEmojis,
        },
      ],
    });

    document.querySelector('#emoji-picker-container').appendChild(picker);
  }
}
